import { createReducer, on, Action } from '@ngrx/store';
import { TextToSpeechState, initialAppState } from '../app.interface';
import * as actions from '@store/actions/tts.action';
export const reducer = createReducer(
	initialAppState.TextToSpeechState,
	on(actions.SetWsConnectionID, (state, payload): TextToSpeechState => {
		return {
			...state,
			wsConnectionID: payload.value
		}
	}),
	on(actions.EnablingTts, (state, payload): TextToSpeechState => {
		return {
			...state,
			ttsEnabled: payload.value
		}
	}),
	on(actions.TtsIsPlaing, (state, payload): TextToSpeechState => {
		return {
			...state,
			ttsIsPlaing: payload.ttsIsPlaing
		}
	})
);


export function TextToSpeechReducer(state: TextToSpeechState | undefined, action: Action): TextToSpeechState {
	return reducer(state as TextToSpeechState, action as Action);
}