import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AppRoutes } from '@utils/routes';

export const SubDomainGuard: CanMatchFn = (route, segments): boolean => {
	let document = inject(DOCUMENT);
	let pattern = new RegExp("(" + AppRoutes.UKNOW + "\/)+.")
	if (pattern.test(document.location.pathname)) {
		let router = inject(Router);
		router.navigate([document.location.pathname])
	}
	return true;
}