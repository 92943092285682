export const PERSONAL_FOLDER_NAME = '$Personal';
export const IMAGE_FOLDER_NAME = '$Image';
export const MAIA_DEFAULT_DOCUMENT = 15425;
export const UDRIVE_PERMITTED_ALL = [
	"text/plain",
	"application/pdf",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	"application/vnd.ms-excel",
	"image/gif",
	"image/jpeg",
	"image/png",
	"image/bmp",
	"image/tiff",
	"image/webp",
	"audio/flac",
	"audio/mp4",
	"audio/mpeg",
	"audio/ogg",
	"audio/opus",
	"audio/wav",
	"audio/webm",
	"audio/m4a"
];
export const UDRIVE_PERMITTED_FILE = [
	"text/plain",
	"application/pdf",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	"application/vnd.ms-excel"
];

export const UDRIVE_PERMITTED_IMAGE = [
	"image/gif",
	"image/jpeg",
	"image/png",
	"image/bmp",
	"image/tiff",
	"image/webp",
];
export const UDRIVE_PERMITTED_AUDIO = [
	"audio/flac",
	"audio/mp4",
	"audio/mpeg",
	"audio/ogg",
	"audio/opus",
	"audio/wav",
	"audio/webm",
	"audio/m4a"
];
export const UDRIVE_FILE_COLOR = {
	".jpg": "#3c309f",
	".jpeg": "#3c309f",
	".png": "#3c309f",
	".tif": "#3c309f",
	".tiff": "#3c309f",
	".bmp": "#3c309f",
	".gif": "#3c309f",
	".webp": "#3c309f",
	".txt": "#f1c406",
	".doc": "#285290",
	".docx": "#285290",
	".ppt": "#c84324",
	".pptx": "#c84324",
	".pdf": "#ad0b00",
	".xls": "#00733b",
	".xlsx": "#00733b",
	".aac": "#9971a6",
	".m4a": "#9971a6",
	".mp3": "#9971a6",
	//".x-midi": "#9971a6",
	".mpeg": "#9971a6",
	".ogg": "#9971a6",
	".oga": "#9971a6",
	".opus": "#9971a6",
	".wav": "#9971a6",
	".webm": "#9971a6",
	".weba": "#9971a6",
	"url": "#14b8a5"
};
export const UDRIVE_IMAGE_EXT = [
	".jpg",
	".jpeg",
	".png",
	".tif",
	".tiff",
	".bmp",
	".gif",
	".webp"
];
export const UDRIVE_FILE_EXT = [
	".txt",
	".doc",
	".docx",
	".ppt",
	".pptx",
	".pdf",
	".xls",
	".xlsx",
	"url"
];
export const UDRIVE_AUDIO_EXT = [
	".aac",
	".midi",
	".mp3",
	".x-midi",
	".mpeg",
	".ogg",
	".oga",
	".opus",
	".wav",
	".webm",
	".weba",
	".m4a"
];

export const MAX_CHAT_ATTACHMENTS = 10;
export const MAX_FILE_SIZE = 10;//Mb
//export const MAX_UPLOAD_UDRIVE = 5;