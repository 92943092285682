import { HttpBackend, HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@approot/app-routing.module';

import { AppComponent } from '@approot/app.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthService } from '@services/auth.service';
import { NavigationService } from '@services/navigation.service';
import { MobileViewService } from '@services/isMobileView.service';


import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';
import { GOOGLE } from '@constants/google.constant';
import { reducers } from '@store/index';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from '@store/effects/user.effect';
import { ChatEffects } from '@store/effects/chat.effect';
import { PreviousUrlService } from '@services/previousUrl.service';
import { PersonalSettingsEffects } from '@store/effects/personalSettings.effect';
//import { ContactsEffects } from '@store/effects/contacts.effect';
import { SearchEffects } from '@store/effects/search.effect';
import { PersonalGraphEffects } from '@store/effects/personalGraph.effect';
import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { CookiebotConfig } from '@approot/cookiebot.config';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FullscreenService } from '@services/fullscreen.service';
//import { CalendarEffects } from '@store/effects/calendar.effect';
import { isString } from '@utils/genericUtils';
import { UdriveEffects } from '@store/effects/udrive.effect';

import { UknowEffects } from '@store/effects/uknow.effect';
import { ErrorInterceptor } from '@approot/interceptors/error.interceptor';
import { ModalService } from '@services/modal.service';

import { AppInitService } from './services/app-init.service';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { WebSocketService } from './services/websocket.service';
import { MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { IconcontainerComponent } from './components/iconcontainer/iconcontainer.component';



export const translatorConf = {
	loader: {
		provide: TranslateLoader,
		useFactory: HttpLoaderFactory,
		deps: [HttpBackend],
	},
	isolate: false
};

export const MatTooltipOptions: MatTooltipDefaultOptions = {
	showDelay: 0,
	hideDelay: 0,
	touchGestures: 'auto',
	touchendHideDelay: 0,
	disableTooltipInteractivity: true,
}



@NgModule({
	declarations: [
		AppComponent,
		IconcontainerComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot(translatorConf),
		StoreModule.forRoot(reducers),
		NgxCookiebotModule.forRoot(CookiebotConfig),
		NgxGoogleAnalyticsModule.forRoot(GOOGLE.googleAnaliticsCode),
		NgxGoogleAnalyticsRouterModule,
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: environment.production,
		}),
		EffectsModule.forRoot([
			UserEffects,
			ChatEffects,
			PersonalSettingsEffects,
			//ContactsEffects,
			SearchEffects,
			PersonalGraphEffects,
			//CalendarEffects,
			UdriveEffects,
			UknowEffects
		]),
		LoaderModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production || environment.test || environment.demo,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		})
	],
	providers: [
		//!!!!!! Scommentare per riattivare il redirect
		// AppInitService,
		// { provide: APP_INITIALIZER, useFactory: MobileRedirect, deps: [AppInitService], multi: true },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true,
		},
		AuthService,
		NavigationService,
		MobileViewService,
		PreviousUrlService,
		ModalService,
		FullscreenService,
		WebSocketService
	],
	bootstrap: [AppComponent],
})
export class AppModule { }


export function HttpLoaderFactory(httpBackend: HttpBackend) {
	return new TranslateHttpLoader(
		new HttpClient(httpBackend),
		'./assets/i18n/',
		'.json'
	);
}


export function MobileRedirect(appInitService: AppInitService) {
	return (): Promise<any> => {
		return appInitService.Init();
	}

}


const original = DefaultValueAccessor.prototype.registerOnChange;
DefaultValueAccessor.prototype.registerOnChange = function (fn) {
	return original.call(this, value => {
		const trimmed = isString(value) ? value.trim() : value;
		return fn(trimmed);
	})
};




