import { createAction, props } from '@ngrx/store';
import { ttsWsModel } from '@models/tts.model';


export const SetWsConnectionID = createAction(
    '[StoreTts] Set web socket connectionId ',
    props<{ value: string }>()
);

export const EnablingTts = createAction(
    '[StoreTts] Enabling Tts response',
    props<{ value: boolean }>()
);


export const TtsIsPlaing = createAction(
    '[StoreTts] Tts is playng status',
    props<{ ttsIsPlaing: number }>()
);
