export enum AppRoutes {
	BASE = '/',
	NOT_FOUND = 'not-found',
	AUTH = 'auth',
	SIGN_UP = 'sign-up',
	ON_BOARD = 'on-board',
	MYMAIA = 'my-maia',
	PERSONALGRAPH = 'personal-graph',
	CONTACTS = 'contacts',
	WELCOME = 'welcome',
	CALENDAR = 'maia-calendar',
	SHARE = 'share',
	STORES = 'stores',
	UKNOW = 'uknow',
	UDRIVE = 'file',
	GUESTUKNOW = 'guest-uknow',
	UNDER_MAINTAINANCE = 'under-maintainance',
	FORCED_UPDATE = 'update-needed',
	SPOTIFY_CONN = 'spotify-connection'
}
