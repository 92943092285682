<div class="h-[100dvh] w-[100dvw] md:h-auto md:w-auto md:rounded-xl flex w- flex-col overflow-hidden" [@slideContent]="animationState" (@slideContent.start)="onAnimationStart($event)" (@slideContent.done)="onAnimationDone($event)"
	 [class.bg-red-50]="data.modalType === 'danger'"
	 [class.bg-white]="data.modalType !== 'danger'"
	 [ngClass]="data.modalContainerClasses">
	<div class="border-b text-center relative py-3" *ngIf="data.header" [ngClass]="data.modalType === 'danger' ? 'border-red-700 text-red-700' : data.modalType === 'warning' ? 'border-orange-700 text-orange-700' :'border-gray-200' ">
		<maia-button [icon]="'arrow_back'" [outlined]="'hidden'" (click)="dialogRef.close()" *ngIf="data.backBtn" class="absolute left-3 top-2/4 -translate-y-1/2" [btnType]="data.modalType !== 'danger' || data.modalType !== 'warning' ? 'primary' : 'secondary'"></maia-button>
		<div class="font-bold text-base px-16">{{data.title ? data.title : '&nbsp;'}}</div>
		<maia-button [icon]="'close'" [outlined]="'hidden'" (click)="dialogRef.cancel()" *ngIf="data.closeBtn" class="absolute left-3 top-2/4 -translate-y-1/2" [btnType]="data.modalType !== 'danger' || data.modalType !== 'warning' ? 'primary' : 'secondary'"></maia-button>
		<maia-button [label]="data.labelBtn" [outlined]="'hidden'" [size]="'small'" (click)="dialogRef.close('postAction')" *ngIf="data.closeBtn" class="absolute right-3 top-2/4 -translate-y-1/2" [btnType]="data.modalType !== 'danger' || data.modalType !== 'warning' ? 'primary' : 'secondary'"></maia-button>
	</div>
	<div class="w-full flex-1" [ngClass]="data.modalContentCasses">
		<ng-container cdkPortalOutlet></ng-container>
	</div>
</div>