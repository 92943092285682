import { LinkObject, NodeObject } from "force-graph";

export interface PpgData {
	[index: string]: PpgDataNode[]
}

export interface PpgDataNode {
	id: number;
	id_user: string;
	subject: string;
	label: string;
	relationship: string;
	relationship_en: string;
	relationship_it: string;
	relationship_fr: string;
	info_en: null | string;
	info_it: null | string;
	info_fr: null | string;
	obj_type: string;
	placeholder_en: string;
	placeholder_it: string;
	placeholder_fr: string;
	is_node: boolean;
	value: any;
	personal_data: PpgDataNode[];
	sons: Son[];
	accorpato: PpgDataNode[]
}

export interface PpgRenderStructure {
	nodes: PpgRenderNode[],
	links: PpgRenderLink[]
}

export interface PpgRenderNode extends NodeObject {
	__bckgDimensions?: number[]
	val: number,
	completed?: number,
	value?: string,
	sub?: boolean,
	relationship: string,
	relationshipDisp?: string,
	cliccable?: boolean,
	index?:number
}

export interface PpgRenderLink extends LinkObject {
	relationshipDisp?: string
}




export interface PpgDictionaryModel {
	label: string;
	relationship: string;
	relationship_en: string;
	relationship_it: string;
	relationship_fr: string;
	category: string;
	category_en: string;
	category_it: string;
	category_fr: string;
	info_en: null | string;
	info_it: null | string;
	info_fr: null | string;
	obj_type: NodeType;
	placeholder_en: string;
	placeholder_it: string;
	placeholder_fr: string;
	sons: PpgDictionaryModel[];
	is_node: boolean;
}

export enum NodeType {
	ARRAY = "array",
	DATE = "date",
	INTEGER = "int",
	SELECT = "select",
	STRING = "str",
	STRING2 = "string",
}

export interface Son {
	label: string;
	label_father: string;
	relationship_en: string;
	relationship_it: string;
	relationship_fr: string;
	info_en: null | string;
	info_it: null | string;
	info_fr: null | string;
	obj_type: NodeType;
	placeholder_en: string;
	placeholder_it: string;
	placeholder_fr: string;
	is_node: boolean;
}


export interface DefaultCategoriesModel {
	[index: string]: {
		[index: string]: string;
	}
}


export interface CudNodeModel {
	deleteList: number[],
	createList: PpgNewInfoNode[],
	updateList: PpgNodeUpdate[],
	id_user: string
}

export interface PpgNodeUpdate {
	id_user: string,
	subject: string,
	label: string,
	value: string | number,
	id: number
}

export interface PpgNewInfoNode {
	id_user: string,
	subject: string,
	label: string,
	value: string | number,
	index?: number
}