/**
	* Manage websocket service
*/
import { Injectable } from '@angular/core';
import { map, Observable, Observer, retry, share, Subject } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { environment } from '@environments/environment';
import { WebsocketMessages } from '@models/generic.model';

export interface Message {
	type: string,
	value: any
}

@Injectable()
export class WebSocketService {
	private WS_ENDPOINT = window.sessionStorage.getItem('maia_service') && window.sessionStorage.getItem('maia_service') !== '' ? `wss://apimgmt.synapsia.ai/maestroWeb${window.sessionStorage.getItem('maia_service')!}` : environment.wsUrl;
	private subject!: AnonymousSubject<MessageEvent> | null;
	public messages!: Subject<Message>;
	private initSource = new Subject<string>();
	public refreshWsConnection = this.initSource.asObservable();
	private socket!: WebSocket;
	private resetInterval!: ReturnType<typeof setTimeout>
	constructor() { }
	public init() {
		this.messages = <Subject<Message>>this.connect().pipe(
			map((response: MessageEvent): Message => {
				try {
					let data = JSON.parse(response.data)
					return data;
				} catch (error) {
					return {
						type: WebsocketMessages.ERROR,
						value: "Generic error",
					}
				}

			})
		);
		this.socket.addEventListener("error", (event) => {
			clearInterval(this.resetInterval);
			this.resetInterval = setInterval(() => {
				this.init();
			}, 8000);
		});
		this.socket.addEventListener("open", (event) => {
			clearInterval(this.resetInterval);
			this.initSource.next('init')
		});
	}



	/* connection function */
	private connect(): AnonymousSubject<MessageEvent> {
		this.subject = null;
		if (!this.subject) {
			this.subject = this.create();
		}
		return this.subject;
	}
	/* websocket creation function */
	create(): AnonymousSubject<MessageEvent> {
		this.socket = new WebSocket(this.WS_ENDPOINT);
		let observable = new Observable((obs: Observer<MessageEvent>) => {
			this.socket.onmessage = obs.next.bind(obs);
			this.socket.onerror = obs.error.bind(obs);
			this.socket.onclose = obs.complete.bind(obs);
			return this.socket.close.bind(this.socket);
		}).pipe(
			share(),
			retry()
		);
		let shareObs = observable.pipe(share());
		let observer = {
			error: (error: any) => {
				this.initSource.next('error');
				return error
			},
			complete: () => {
				this.initSource.next('complete');
			},
			next: (data: Object) => {
				if (this.socket.readyState === WebSocket.OPEN) {
					this.socket.send(JSON.stringify(data));
				}
			}
		};
		return new AnonymousSubject<MessageEvent>(observer, shareObs);
	}
	close() {
		if (this.socket) {
			this.socket.close();
			this.subject = null;
		}
	}
}