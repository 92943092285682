import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { NavigationService } from '@services/navigation.service';

import { Observable, Subject, takeUntil } from 'rxjs';

import { PreviousUrlService } from '@services/previousUrl.service';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';

import { SetCurrPath } from '@store/actions/personalSettings.action';
import { AppRoutes } from '@utils/routes';
import { UserInformations } from './models/setting.model';
import * as personalSelectors from '@store/selectors/personalSettings.selector';
import { LocalStorageService } from '@services/locaStorage.service';
import { MobileViewService } from '@services/isMobileView.service';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	//animations: [routerTransition],
})
export class AppComponent implements OnInit, OnDestroy {
	appRoutes = typeof AppRoutes;
	//isMobileView: boolean = false;
	//showBackgroundImg: boolean = false;
	//backgroundUrl: string = '';
	loadingRouteConfig!: boolean;
	//updateComponent = environment.develop;
	//@HostListener('window:resize', ['$event'])
	// onResize() {
	// 	this.mobileViewService.setIsMobile();
	// }
	// @HostListener('window:orientationchange', ['$event'])
	// onOrientationChange() {
	// 	this.mobileViewService.setIsMobile();
	// }
	$userInformation: Observable<UserInformations | null> = this.store.select(personalSelectors.getInformation);

	private destroyed$: Subject<boolean> = new Subject<boolean>();
	constructor(
		private translate: TranslateService,
		private navigationService: NavigationService,
		private mobileViewService: MobileViewService,
		private localStorage: LocalStorageService,
		private previousUrlService: PreviousUrlService,
		private router: Router,
		protected route: ActivatedRoute,
		private store: Store
	) {
		this.router.events.subscribe((event) => {
			// if (event instanceof ActivationEnd) {
			// 	const snapshot = event.snapshot;
			// 	//this.// = snapshot.data[showBackgroundStateProperty];
			// }
			if (event instanceof NavigationEnd) {
				let url = event.url;
				if (event.url.indexOf(';') >= 0) {
					url = event.url.replace(event.url.slice(event.url.indexOf(';'), event.url.length), '')
				}
				this.previousUrlService.setPreviousUrl(url);
				this.store.dispatch(SetCurrPath({ currPath: url }));
			}
			// if (event instanceof RouteConfigLoadStart) {
			// 	this.loadingRouteConfig = true;
			// } else if (event instanceof RouteConfigLoadEnd) {
			// 	this.loadingRouteConfig = false;
			// }
		});
	}
	ngOnInit(): void {
		this.mobileViewService.setIsMobile();
		// combineLatest([
		// 	this.mobileViewService.isMobile,
		// 	this.store.select(chatSelectors.selectIsUknowChat)
		// ]).pipe(takeUntil(this.destroyed$), debounceTime(10)).subscribe(res => {
		// 	if (!Boolean(localStorage.getItem('maia_boarded')) && res[0] && !res[1]) {
		// 		this.backgroundUrl = '/assets/images/maia-onboarding-background.jpg';
		// 	} else {
		// 		this.backgroundUrl = '';
		// 	}
		// 	this.isMobileview = res[0]
		// });
		this.$userInformation.pipe(takeUntil(this.destroyed$)).subscribe((val) => {
			if (val!.language !== '') {
				this.translate.use(val!.language!)
				this.localStorage.setItem('maia_lang', val!.language);
			} else {
				this.translate.use(navigator.language.split('-')[0]);
				this.localStorage.setItem('maia_lang', this.translate.use(navigator.language.split('-')[0]));
			}
		});

	}

	getRouteAnimation(outlet: RouterOutlet) {
		return this.navigationService.animationValue;
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
}
