import { Component } from '@angular/core';
import { ModalRef } from '@shared/components/modal/modal-ref';

@Component({
	selector: 'maia-user-instance-not-allowed',
	templateUrl: './user-instance-not-allowed.component.html',
	styleUrls: ['./user-instance-not-allowed.component.scss']
})
export class UserInstanceNotAllowedComponent {
	constructor(
		public dialogRef: ModalRef
	) { }


	closeModal() {
		this.dialogRef.close();
	}
}
