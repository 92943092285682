<div class="md:w-[50vw] lg:w-64 w-screen" *ngIf="true">
    <div class="flex flex-col text-center justify-center border-b border-gray-medium py-4 bg-white z-10 w-full relative top-0 rounded-t-xl">
        <maia-button [icon]="'close'" [outlined]="'hidden'" (click)="closeModal()" class="absolute left-3"></maia-button>
        <span class="font-bold">{{'COINS.COIN_OVER.modal_title' | translate}}</span>
    </div>
    <div class="flex flex-col py-4 justify-center items-center">
        <maia-icons [icon]="'info'" [width]="'54px'" class="text-red-700"></maia-icons>
        <p [innerHTML]="'COINS.COIN_OVER.modal_msg' | translate" class="text-center mt-2 mb-8 font-medium"></p>
        <a href="https://mymaia.ai/shop/" target="_blank" class="bg-maia-petrol-800 hover:bg-maia-petrol-500 transition-colors flex rounded-xl px-2 py-1 text-xs text-white shadow-custom">
            {{'COINS.COIN_OVER.modal_call_to_action' | translate}}
        </a>
    </div>
</div>