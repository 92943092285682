import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TextToSpeechState } from '../app.interface';

export const selectChatFeature = createFeatureSelector<TextToSpeechState>('TextToSpeechState');


export const selectTtsEnabled = createSelector(
	selectChatFeature,
	(state: TextToSpeechState) => {
		return state.ttsEnabled
	}
);

export const selectWsConnectionId = createSelector(
	selectChatFeature,
	(state: TextToSpeechState) => {
		return state.wsConnectionID
	}
);

export const selectTtsIsPlaing = createSelector(
	selectChatFeature,
	(state: TextToSpeechState) => {
		return state.ttsIsPlaing
	}
);