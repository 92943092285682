import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as chatActions from '../actions/chat.action';
import * as chatSelectors from '@store/selectors/chat.selector';
import * as userSelectors from '@store/selectors/user.selector'
import * as personalSettingsSelectors from '@store/selectors/personalSettings.selector';
import * as ttsSelectors from '@store/selectors/tts.selector';
import * as udriveActions from '@store/actions/udrive.action';
import { catchError, forkJoin, map, mergeMap, of, switchMap, tap, withLatestFrom, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { ChatService } from '@services/chat.service';
import { AttachmentGetModel, ChatSidebarItemModel, ConvDriveCUModel, DefaultAnswerModel, MessageType, Query, ReplaceableString, ResponseCategoryUiCardType, SendedMessageModel } from '@models/chat.model';
import { v4 as uuid } from 'uuid';
import * as presonalSettingsActions from '@store/actions/personalSettings.action';

import { environment } from '@environments/environment';
import * as userACtions from '../actions/user.action';
import { HttpErrorResponse } from '@angular/common/http';
import { UKnowService } from '@services/uknow.service';
import { UdriveService } from '@services/udrive.service';
import { WebsocketMessages } from '@models/generic.model';
import { TranslateService } from '@ngx-translate/core';
import { QuickActionType, UdriveQuickActionModel } from '@models/udrive.model';
import { MAIA_DEFAULT_DOCUMENT } from '@approot/constants/udrive.constant';

@Injectable()
export class ChatEffects {


	newChat$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.NewChatAdded),
			map((res) => {
				return chatActions.RestoreChat({ value: { msg: [], hst: [], attachments: null } })
			})
		)
	});


	selectChat$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.SelectChat),
			tap(() => {
				this.store.dispatch(chatActions.LoadChat({ value: true }));
			}),
			switchMap((action) => {
				return forkJoin({
					conversation: this.chatService.getConversationById(action.value.convId),
					attachments: this.chatService.convDriveGetAttachments(action.value.convId)
				}).pipe(
					map((data) => {
						let tmpMsg: DefaultAnswerModel[] = [];
						let tmpHst: any[][] = [];
						data.conversation.forEach((element, index) => {
							let tmpElement: any = { ...element };
							if ((tmpElement.msg![0].text === '' || tmpElement.msg![0].text === null) && (tmpElement.msg![0].caorusel && tmpElement.msg![0].caorusel.length < 0)) {
								tmpElement.msg![0].text = 'No answer';
							}
							tmpElement.answer = tmpElement.msg!;
							tmpElement.msgType = index % 2 === 0 ? MessageType.Q : MessageType.A;
							delete tmpElement.msg;
							tmpElement.time = tmpElement.time
							let answersCopy = JSON.parse(JSON.stringify(tmpElement.answer));
							answersCopy.forEach((answer: any) => {
								delete answer.data;
								delete answer.entities;
								answer.category = tmpElement.category;
							});
							tmpHst.push(answersCopy);
							tmpMsg.push(tmpElement);
						});
						return chatActions.RestoreChat({ value: { msg: tmpMsg, hst: tmpHst, attachments: data.attachments, hasAttachments: data.attachments ? true : false } })
					})
				)
			})
		)
	});

	selectSharedChat$ = createEffect(() =>
		this.actions$.pipe(
			ofType(chatActions.SelectSharedChat),
			mergeMap((action) => {
				this.store.dispatch(chatActions.LoadChat({ value: true }));
				return this.chatService.readSharedChat(action.value).pipe(
					map((response: any) => {
						if (response) {
							let tmpMsg: any[] = [];
							let tmpHst: any[][] = [];
							response.chat.forEach((element: any, index: number) => {
								if ((element.msg[0].text === '' || element.msg[0].text === null) && (element.msg[0].caorusel && element.msg[0].caorusel.length < 0)) {
									element.msg[0].text = 'No answer';
								}
								element.answer = element.msg;
								element.msgType = index % 2 === 0 ? MessageType.Q : MessageType.A;
								delete element.msg;
								element.time = element.time
								let answersCopy = JSON.parse(JSON.stringify(element.answer));
								answersCopy.forEach((answer: any) => {
									delete answer.data;
									delete answer.entities;
									answer.category = element.category;
								});
								tmpHst.push(answersCopy);
								tmpMsg.push(element);
							});
							this.store.dispatch(chatActions.RestoreShareChat({ value: { msg: tmpMsg, hst: tmpHst, chatId: response.chat[0].conversation_id, chatTitle: response.chat[0].title, createDate: response.chat[0].create_date, chatOwner: response.userId } }));
							this.store.dispatch(presonalSettingsActions.setShareUser({ value: { lang: response.chat[0].language } }))
						} else {
							this.store.dispatch(presonalSettingsActions.setShareUser({ value: { lang: navigator.language.split('-')[0] } }))
						}
						return chatActions.LoadChat({ value: false });
					})
				);
			})
		)
	);

	selectUknowChat$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.SelectUknowChat),
			tap((action) => {
				let uknowCurrConv = {
					profile: {
						id: null,
						name: action.guest.name,
						surname: action.guest.surname,
						mail: action.guest.email,
						guest_id: action.guest.guest_id,
						conversation_id: action.convId,
						id_user: action.attachments.user_id,
						uknow_id: action.attachments.uknow_id,
						phone: action.guest.phone
					},
					welcome_message: [...action.attachments.welcome_messages]
				}
				sessionStorage.setItem('uknowData', JSON.stringify(uknowCurrConv));
			}),
			map((action) => {
				this.store.dispatch(userACtions.LogInSuccess({
					userDetail: {
						oid: action.guest.guest_id,
						role: '',
						subscriptionKey: '',
						name: action.guest.name,
						surname: action.guest.surname,
						displayName: action.guest.name + ' ' + action.guest.surname,
						sex: 'N',
						emails: action.guest.email,
						profilePhoto: '',
						isPro: false,
						isPlus: false,
						uDriveSize: '',
						exp: 0,
						iss: '',
						aud: ''
					}
				}))
			}),
			map(() => {
				return chatActions.LoadChat({ value: false });
			})
		)
	});

	getChatAttachments$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.getChatAttachments),
			withLatestFrom(
				this.store.select(chatSelectors.selectChatId),
			),
			switchMap(([action, chatId]) => {
				return this.chatService.convDriveGetAttachments(chatId).pipe(
					map(res => {
						return chatActions.setChatAttachments({ value: res })
					})
				)
			})
		)
	});

	checkUpdateAttachedFile$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.checkUpdateAttachedFile),
			withLatestFrom(
				this.store.select(chatSelectors.selectChatAttachments),
			),
			map(([action, currAttachments]) => {
				let found = false;
				if (currAttachments) {
					found = currAttachments!.attachments!.some((attach: AttachmentGetModel) => attach.id === action.updatedAttach.id);
				}
				if (found) {
					this.store.dispatch(udriveActions.updateNewUdrive({ updatedFile: action.updatedAttach }));
					return chatActions.updateChatAttachments({ updatedAttach: action.updatedAttach });
				} else {
					return chatActions.NoopAction();
				}
			})
		)
	});

	searchChat$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.SearchChat),
			switchMap((action) => {
				return this.chatService.searchMaiaConversation(action.string).pipe(
					takeUntil(this.actions$.pipe(ofType(chatActions.GetMaiaConv))),
					map((res) => {
						return chatActions.SetChat({ value: res });
					})
				)
			})
		)
	});

	cleanChatSearch$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.CleanChatSearch),
			map(() => {
				return chatActions.GetMaiaConv({});
			})
		)
	});

	sendMessage$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.SendMessage),
			withLatestFrom(
				this.store.select(chatSelectors.selectChat),
				this.store.select(userSelectors.selectCurrentUser),
				this.store.select(chatSelectors.selectChatHistory),
				this.store.select(personalSettingsSelectors.getInformation),
				this.store.select(ttsSelectors.selectWsConnectionId),
				this.store.select(ttsSelectors.selectTtsEnabled),
				this.store.select(chatSelectors.selectChatAttachments),
				this.store.select(chatSelectors.isMacroActive),
				this.store.select(chatSelectors.selectChatExpertise)
			),
			switchMap(([action, currChat, currUser, currHistory, informations, connectionId, isTtsEnabled, storedAttachments, isMacroActive, expertise]) => {
				let attachmentList = storedAttachments?.attachments.map((attach: AttachmentGetModel) => {
					if (attach.uDriveFile) {
						return {
							id: '' + attach.id,
							type: 'file'
						}
					} else {
						return {
							id: attach.url,
							type: 'url'
						}
					}
				})
				let queryParams:Query = {
					use_personality: false,
					environment: environment.environment,
					id_persona: informations.language === 'en' ? 500 : informations.language === 'it' ? 543 : informations.language === 'fr' ? 520 : 537,
					msg: action.value.msg,
					userId: currUser.oid,
					conversationId: currChat.chatId,
					history: currHistory,
					use_tts: isTtsEnabled,
					connectionId: connectionId!,
					customer: 'maia',
					expertise: expertise,
					...(attachmentList && { attachment: [...attachmentList] }),
					...(storedAttachments?.conv_obj && storedAttachments?.conv_obj !== '' && { convObj: storedAttachments.conv_obj }),
					...(currChat.uknowOwnerId && { ownerId: currChat.uknowOwnerId })
				}

				if (isMacroActive) {
					this.store.dispatch(chatActions.resetMacro())
				}

				return this.chatService.query(queryParams).pipe(
					takeUntil(this.actions$.pipe(ofType(chatActions.StopAnswer))),
					map(response => {
						response.msgType = 'A';
						return chatActions.ReciveMessage({ value: response });
					}),
					catchError((err) => {
						return of(chatActions.ReciveMessage({
							value: {
								answer: [{
									text: 'No Answer',
									answer_type: ResponseCategoryUiCardType.DIALOG,
									ui_card: ResponseCategoryUiCardType.DIALOG,
									id_adam_conversation_history: JSON.parse(err.error.developerMessage).res.answer[0].id_adam_conversation_history,
									id: JSON.parse(err.error.developerMessage).res.answer[0].id
								}],
								msgType: "A",
								conversationId: JSON.parse(err.error.developerMessage).conversationId,
							}
						}));
					})
				)
			}),
		)
	});



	reciveMessage$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.ReciveMessage),
			withLatestFrom(
				this.store.select(chatSelectors.selectChat)
			),
			mergeMap(([action, currChat]) => {
				if (action.value.conversationId !== currChat.chatId) {
					chatActions.NoopAction()
				}
				return this.chatService.getConversationById(currChat.chatId).pipe(
					map(res => {
						let tmpHst: any[][] = [];
						res.forEach((element, index) => {
							let tmpElement: any = { ...element };
							if ((tmpElement.msg![0].text === '' || tmpElement.msg![0].text === null) && (tmpElement.msg![0].caorusel && tmpElement.msg![0].caorusel.length < 0)) {
								tmpElement.msg![0].text = 'No answer';
							}
							tmpElement.answer = tmpElement.msg!;
							tmpElement.msgType = index % 2 === 0 ? MessageType.Q : MessageType.A;
							delete tmpElement.msg;
							tmpElement.time = tmpElement.time
							let answersCopy = JSON.parse(JSON.stringify(tmpElement.answer));
							answersCopy.forEach((answer: any) => {
								delete answer.data;
								delete answer.entities;
								answer.category = tmpElement.category;
							});
							tmpHst.push(answersCopy);
						});
						return chatActions.MakeChatHistory({ value: [...tmpHst] })
					})
				);
				
			})
		)
	});

	wSMessage$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.WSMessage),
			withLatestFrom(
				this.store.select(chatSelectors.selectSentByuser),
				this.store.select(chatSelectors.selectChatList),
				this.store.select(chatSelectors.selectChatId),
				this.store.select(chatSelectors.selectChatMSg)
			),
			map(([action, sentByUser, chatList, chatId, chatMsg]) => {
				if (!sentByUser && (chatId === action.updateObject.convId)) {
					if (action.wsEvent === WebsocketMessages.NEWCONVERSATIONADAM || action.wsEvent === WebsocketMessages.NEWMESSAGESEND || action.wsEvent === WebsocketMessages.NEWMESSAGERECEIVED || action.wsEvent === WebsocketMessages.CONVERSATIONDRIVEUPDATE) {
						return chatActions.SelectChat({ value: action.updateObject });
					}
					if (action.wsEvent === WebsocketMessages.CONVERSATIONDRIVEDELETE) {
						if (chatMsg.length <= 0) {
							let tmpConvId = uuid();
							return chatActions.NewChatAdded({
								value: {
									convId: tmpConvId,
									title: this.translate.instant('MAIA.LIST.default_title'),
									lastMsg: '',
									lastMsgTime: '',
									newMessage: 0,
									msgType: MessageType.Q,
									conversationType: ResponseCategoryUiCardType.DIALOG,
									category: '',
									isPinned: false,
									hasAttachments: false,
									hasUKnow: false,
									//task: ChatTasks.NONE,
									isQA: false,
									freeTalk: false,
									expertise: 'base'
								}
							})
						} else {
							let chatToReload = chatList!.find((chat: ChatSidebarItemModel) => chat.convId === action.updateObject.convId)!;
							return chatActions.SelectChat({ value: chatToReload });
						}
					}
				}
				return chatActions.NoopAction();
			})
		)
	});

	WSMessageAddDeleteConvCheckList$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.WSMessageAddDeleteConvCheckList),
			withLatestFrom(
				this.store.select(chatSelectors.selectChatList),
				this.store.select(chatSelectors.selectChatId)
			),
			map(([action, chatList, chatId]) => {
				let count = 0;
				let pinned;
				if (action.wsEvent === WebsocketMessages.DELETECONVERSATIONS) {
					pinned = chatList?.filter(obj => {
						return obj.isPinned === true
					})!.length
					action.convList.forEach(convId => {
						chatList?.map((chat) => {
							if (chat.convId === convId) count++
						});
						if (convId === chatId) {
							let tmpConvId = uuid();
							this.store.dispatch(chatActions.NewChatAdded({
								value: {
									convId: tmpConvId,
									title: this.translate.instant('MAIA.LIST.default_title'),
									lastMsg: '',
									lastMsgTime: '',
									newMessage: 0,
									msgType: MessageType.Q,
									conversationType: ResponseCategoryUiCardType.DIALOG,
									category: '',
									isPinned: false,
									hasAttachments: false,
									hasUKnow: false,
									//task: ChatTasks.NONE,
									isQA: false,
									freeTalk: false,
									expertise: 'base'
								}
							}));
						}
					})
					if (count > 0) {
						let pageSize = Math.ceil((chatList!.length - pinned! - action.convList.length) / action.convList.length) + pinned!
						this.store.dispatch(chatActions.GetMaiaConvPagedSliced({ size: pageSize, page: 2, count }));
					}
					return chatActions.WSMessageAddConv({ convList: action.convList, wsEvent: action.wsEvent });
				} else {
					return chatActions.NoopAction();
				}
			})
		)
	});




	removeChat$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.RemoveChat),
			withLatestFrom(
				this.store.select(chatSelectors.selectChat),
				this.store.select(chatSelectors.selectChatToDel)
			),
			mergeMap(([action, currChat, chatToDel]) => {
				let toDel = action.value ? action.value : chatToDel;
				if (chatToDel.indexOf(currChat.chatId) >= 0 || (action.value && currChat.chatId === action.value[0])) {
					this.store.dispatch(chatActions.CleanChatStatus());
				}
				return this.chatService.deleteConversationList(toDel).pipe(
					map(res => {
						return chatActions.CleanRemoveChat();
					})
				);
			})
		);
	});




	removeChatExchange$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.RemoveChatExchange),
			withLatestFrom(
				this.store.select(chatSelectors.selectChatMSg)
			),
			map(([action, currMsgs]) => {
				let id: number | undefined = undefined;
				let i: number;
				if (action.value.id) {
					id = action.value.id
				} else {
					currMsgs.forEach((element: any, index: number) => {
						element.answer.find((answ: DefaultAnswerModel) => {
							if (answ.text === action.value.text) i = index
						})
						return i!
					});
					id = currMsgs[i! + 1].answer[0].id_adam_conversation_history;
				}
				return { id: id!, index: i! }
			}),
			withLatestFrom(
				this.store.select(chatSelectors.selectChat)
			),
			map(([{ id, index }, currChat]) => {
				this.store.dispatch(chatActions.onRemoveChatExchange({ value: { id, index } }));
				this.chatService.deleteConversationExchange(currChat.chatId, id).subscribe()
			})
		);
	}, { dispatch: false });


	GetMaiaConvAndDrive$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.GetMaiaConvAndDrive),
			switchMap((action) => {
				return forkJoin({
					maiaConv: this.chatService.readMaiaConvPinned(),
					udrive: this.udriveService.readDrive()
				}).pipe(
					switchMap((data) => {
						return [
							chatActions.SetChat({ value: data.maiaConv }),
							udriveActions.setUdrive({ udriveResponse: data.udrive, chatAttachLength: 0 })
						]
					})
				)
			})
		)
	});

	GetMaiaConvPaged$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.GetMaiaConvPaged),
			switchMap(action => {
				return this.chatService.readMaiaConvPaged(action.size, action.page).pipe(
					map((res) => {
						return chatActions.SetChat({ value: res })
					})
				)
			})
		)
	});

	GetMaiaConvPagedSliced$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.GetMaiaConvPagedSliced),
			switchMap(action => {
				return this.chatService.readMaiaConvPaged(action.size, action.page).pipe(
					map((res) => {
						let pp = {
							...res,
							adamConversation: res.adamConversation.slice(0, action.count)
						}
						return chatActions.SetChat({ value: pp })
					})
				)
			})
		)
	});



	getMaiaConv$ = createEffect(() =>
		this.actions$.pipe(
			ofType(chatActions.GetMaiaConv),
			withLatestFrom(
				this.store.select(chatSelectors.selectChatList),
			),
			mergeMap(([action, chatList]) => {
				return this.chatService.readMaiaConvPinned().pipe(
					map(res => {
						if (action.isUknow === true) {
							return chatActions.SetChatUknow({ value: res });
						} else {
							if (chatList) {
								let parsedArray = JSON.parse(JSON.stringify(chatList))
								if (!this.chatService.arraysChatAreEqual(res.adamConversation, parsedArray)) {
									return chatActions.SetChat({ value: res });
								} else {
									return chatActions.EqualState();
								}
							} else {
								return chatActions.SetChat({ value: res });
							}
						}
					})
				)
			})
		)
	);

	updateChatTitle$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.ManualUpdateChatTitle),
			withLatestFrom(
				this.store.select(chatSelectors.selectChat)
			),
			tap(([action, currChat]) => {
				return this.chatService.updateConversationTitle(action.chatId ? action.chatId : currChat.chatId, action.newTitle).subscribe();
			})
		)
	}, { dispatch: false });


	updateChatPinned$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.UpdateChatPinned),
			tap((action) => {
				return this.chatService.updateConversationPinned(action.value.convId, action.value.pinned).subscribe();
			})
		)
	}, { dispatch: false });


	createUknowChat$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.createUknowChat),
			tap(() => {
				this.store.dispatch(udriveActions.setSidePanelOpen({ value: false }));
			}),
			withLatestFrom(
				this.store.select(chatSelectors.selectChatId),
				this.store.select(userSelectors.selectCurrentUser)
			),
			switchMap(([action, chatId, user]) => {
				let tmpAttachments: { id: number; order: number; }[] = [];
				action.selectedFile.forEach((newAttach, index) => {
					tmpAttachments.push({
						id: newAttach,
						order: index
					})
				})
				let body: ConvDriveCUModel = {
					conversation_id: chatId,
					id_user: user.oid,
					attachments: [...tmpAttachments],
					conv_obj: null,
					...(action.isQA && { is_qa: action.isQA }),
				};
				return this.chatService.convDriveCreate(body).pipe(
					// tap(() => {
					// 	if (tmpTask) this.store.dispatch(chatActions.setChatTask({ task: tmpTask }))
					// }),
					map(() => {
						return udriveActions.setMultiSelectionList({ list: [], canMultiselect: true });
					})
				)
			})
		)
	});

	updateUknowChat$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.updateUknowChat),
			tap(() => {
				this.store.dispatch(udriveActions.setSidePanelOpen({ value: false }));
			}),
			withLatestFrom(
				this.store.select(chatSelectors.selectChatId),
				this.store.select(userSelectors.selectCurrentUser),
				this.store.select(chatSelectors.selectChatAttachments),
			),
			switchMap(([action, chatId, user, currAttachments]) => {
				let tmpAttachments: { id: number; order: number; }[] = [];
				let lastOrder = 0;
				if (action.selectedFile.length > 0) {
					currAttachments?.attachments.forEach((attach: AttachmentGetModel) => {
						tmpAttachments.push({
							id: attach.id,
							order: attach.order
						})
						attach.order > lastOrder ? lastOrder = attach.order : lastOrder++;
					});
					action.selectedFile.forEach((newAttach) => {
						let isPresent = currAttachments?.attachments.find((attach: AttachmentGetModel) => attach.id === newAttach)
						if (!isPresent) {
							tmpAttachments.push({
								id: newAttach,
								order: lastOrder
							})
							lastOrder++;
						} else {
							let index = currAttachments?.attachments.indexOf(isPresent) as number;
							let tmpList = [...currAttachments?.attachments!];
							tmpList.splice(index, 1);
							tmpAttachments = [...tmpList];
						}
					});
				}
				let body: ConvDriveCUModel = {
					id: currAttachments?.id ? currAttachments?.id : 1,
					conversation_id: chatId,
					id_user: user.oid,
					attachments: [...tmpAttachments],
					conv_obj: null
				};
				return this.chatService.convDriveUpdate(body).pipe(
					map(() => {
						return udriveActions.setMultiSelectionList({ list: [], canMultiselect: true });
					})
				)
			})
		)
	});

	updateUknowChatContext$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.updateUknowChatContext),
			withLatestFrom(
				this.store.select(chatSelectors.selectChatId),
				this.store.select(userSelectors.selectCurrentUser),
				this.store.select(chatSelectors.selectChatAttachments),
			),
			switchMap(([action, chatId, user, currAttachments]) => {
				let tmpAttachments: { id: number; order: number; }[] = [];
				let lastOrder = 0;
				currAttachments?.attachments.forEach((attach: AttachmentGetModel) => {
					tmpAttachments.push({
						id: attach.id,
						order: attach.order
					})
					attach.order > lastOrder ? lastOrder = attach.order : lastOrder++;
				});
				let body: ConvDriveCUModel = {
					id: currAttachments?.id,
					conversation_id: chatId,
					id_user: user.oid,
					attachments: [...tmpAttachments],
					conv_obj: action.context
				};
				return this.chatService.convDriveUpdate(body).pipe(
					map(() => {
						return chatActions.EqualState();
					})
				)
			})
		)
	});

	setChatUknow$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.SetChatUknow),
			withLatestFrom(
				this.store.select(chatSelectors.selectChatId),
				this.store.select(chatSelectors.selectChatList)
			),
			map((data) => {
				return chatActions.SelectChat({ value: data[2]!.filter((obj: ChatSidebarItemModel) => obj.convId === data[1])[0] })
			})
		)
	});
	cleanChatAttachments$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.CleanChatAttachments),
			withLatestFrom(
				this.store.select(chatSelectors.selectChatId),
			),
			switchMap(([action, currChatId]) => {
				return this.chatService.convDriveCleanAttach(currChatId).pipe(
					map(() => {
						return chatActions.EqualState();
					}),
					catchError((res: HttpErrorResponse) => {
						return of(chatActions.UknowWillDeleted({ uknow: JSON.parse(res.error.developerMessage).id_uknow }));
					})
				)
			})
		)
	});

	cleanUknowChat$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.CleanUknowChat),
			withLatestFrom(
				this.store.select(chatSelectors.selectChatId),
				this.store.select(chatSelectors.selectChatMSg),
			),
			switchMap(([action, currChatId, currMsgs]) => {
				let msgsList: number[] = [];
				for (let i = 1; i < currMsgs.length; i += 2) {
					msgsList.push(currMsgs[i].answer[0].id_adam_conversation_history)
				}
				return this.chatService.cleanConversation(currChatId, msgsList).pipe(
					map(() => {
						return chatActions.EmptyUknowChat();
					})
				)
			})
		)
	});

	confirmUknowWillDeleted$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.ConfirmUknowWillDeleted),
			withLatestFrom(
				this.store.select(chatSelectors.selectUknowWillDeleted),
			),
			switchMap(([action, uknowId]) => {
				return this.uknowService.uknowDelete(uknowId as number).pipe(
					map(() => {
						return chatActions.CleanChatAttachments()
					})
				)
			})
		)
	});

	StopAnswer$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.StopAnswer),
			withLatestFrom(
				this.store.select(chatSelectors.selectChatId),
				this.store.select(chatSelectors.selectChatHistory),
				this.store.select(personalSettingsSelectors.getInformation)
			),
			switchMap(([action, chatId, currHistory, informations]) => {
				return this.chatService.stopAnswer(chatId).pipe(
					map((interruptedMsg) => {
						return chatActions.ReciveMessage({
							value: {
								// active_conditioning: null,
								// active_profile: null,
								answer: [{
									id_adam_conversation_history: interruptedMsg,
									text: ReplaceableString.MSG_INTERRUPTED,
									answer_type: "dialog",
									ui_card: "dialog",
									task: null,
									time: new Date(new Date().toISOString().replace('Z', '')).toISOString(),
									reason: null,
									reasoning: null,
									language: null,
									like: 0,
									data: null,
									debug: null,
									entities: null,
									carousel: null,
									events: null,
									tts: null,
									geo: null,
									media_card: null,
									videoShares: null
								}],
								// category: null,
								// history: [...currHistory, [{
								// 	id: null,
								// 	id_adam_conversation_history: interruptedMsg,
								// 	text: ReplaceableString.MSG_INTERRUPTED,
								// 	answer_type: "dialog",
								// 	ui_card: "dialog",
								// 	task: null,
								// 	time: new Date(new Date().toISOString().replace('Z', '')).toISOString(),
								// 	reason: null,
								// 	reasoning: null,
								// 	language: null,
								// 	like: 0,
								// 	data: null,
								// 	debug: null,
								// 	entities: null,
								// 	carousel: null,
								// 	events: null,
								// 	tts: null,
								// 	geo: null,
								// 	media_card: null,
								// 	videoShares: null
								// }]],
								// intent_active_conditioning: '',
								// responseTime: new Date().toISOString(),
								// time: new Date().toISOString(),
								msgType: "A",
								conversationId: chatId,
								// intents: null,
								// carousel: null,
								// debug: {},
								// kobe: null,
								// language: informations.language!,
								// maestroai_query: {},
								// rtk_query: null,
								// serp: []
							}
						});
					})
				)
			})
		)
	});


	MacroDocument$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.MacroDocument),
			withLatestFrom(
				this.store.select(chatSelectors.macroType),
				this.store.select(personalSettingsSelectors.getInformation)
			),
			map(([action, macroType, informations]) => {
				if (macroType === 'image' || macroType === 'text' || macroType === 'external_search') {
					var date = new Date()
					var userTimezoneOffset = date.getTimezoneOffset() * 60000;
					let newMsg: SendedMessageModel = {
						msg: macroType === 'image' ? this.translate.instant('MAIA.WELCOME.COMPONENT.image_prompt') : macroType === 'text' ? this.translate.instant('MAIA.WELCOME.COMPONENT.text_prompt') : this.translate.instant('MAIA.WELCOME.COMPONENT.search_prompt'),
						from: informations.displayName,
						msgType: MessageType.Q,
						time: new Date(date.getTime() + userTimezoneOffset).toISOString()
					};
					return chatActions.SendMessage({ value: newMsg });
				} else {
					let createObj = {
						selectedFile: [MAIA_DEFAULT_DOCUMENT],
						...(macroType === 'quickaction' && { isQA: true })
					}
					return chatActions.createUknowChat(createObj);
				}
			})
		)
	});


	MacroDocumentStep2$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.MacroDocumentStep2),
			withLatestFrom(
				this.store.select(chatSelectors.macroType),
				this.store.select(chatSelectors.selectChatAttachments),
				this.store.select(chatSelectors.selectChat),
				this.store.select(personalSettingsSelectors.getInformation)
			),
			map(([action, macroType, attachment, currChat, informations]) => {
				if (macroType === 'document') {
					var date = new Date()
					var userTimezoneOffset = date.getTimezoneOffset() * 60000;
					let newMsg: SendedMessageModel = {
						msg: this.translate.instant('MAIA.WELCOME.COMPONENT.doc_prompt'),
						from: informations.displayName,
						msgType: MessageType.Q,
						time: new Date(date.getTime() + userTimezoneOffset).toISOString()
					};
					return chatActions.SendMessage({ value: newMsg });
				} else {
					let body: UdriveQuickActionModel = {
						id_file: attachment?.attachments[0].uDriveFile.id as number,
						action: QuickActionType.SUMMARIZE,
						msg: `${this.translate.instant('UDRIVE.action_summarize')}${attachment?.attachments[0].uDriveFile.file_name}`,
						conversationId: currChat.chatId
					};
					this.udriveService.quickActions(body).subscribe();
					return chatActions.resetMacro();
				}

			})
		)
	});


	restoreChat$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(chatActions.RestoreChat),
			withLatestFrom(
				this.store.select(chatSelectors.isMacroActive),
			),
			map(([action, isMacroActive]) => {
				if (isMacroActive) {
					return chatActions.MacroDocumentStep2();
				} else {
					return chatActions.NoopAction();
				}
			})
		);
	});


	constructor(
		private actions$: Actions,
		private chatService: ChatService,
		private uknowService: UKnowService,
		private udriveService: UdriveService,
		private translate: TranslateService,
		private store: Store,
	) { }
}