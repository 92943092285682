import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs';
import { DefaultAnswerModel, MessageType } from '@models/chat.model';
import { UknowReadModel } from '@models/uknow.model';
import { UKnowService } from '@services/uknow.service';
import * as UknowActions from '@store/actions/uknow.action';
import * as ChatActions from '@store/actions/chat.action';


@Injectable()
export class UknowEffects {


	GetConvList$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UknowActions.GetConvList),
			switchMap(() => {
				return this.uknowService.uknowReadAll().pipe(
					map((res: UknowReadModel[]) => {
						return UknowActions.SetConvList({ convList: res })
					})
				)
			})
		);
	})

	ReadGuestConv$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UknowActions.ReadGuestConversation),
			tap((action) => {
				this.store.dispatch(ChatActions.SetChatId({ convId: action.conv.conversation_id! }));
			}),
			switchMap((action) => {
				return this.uknowService.uknowReadGuestConversation(action.conv.conversation_id!, action.conv.guest_id!).pipe(
					map((res) => {
						let tmpMsg: DefaultAnswerModel[] = [];
						res.forEach((element: DefaultAnswerModel, index: number) => {
							let tmpElement: any = { ...element };
							if ((tmpElement.msg![0].text === '' || tmpElement.msg![0].text === null) && (tmpElement.msg![0].caorusel && tmpElement.msg![0].caorusel.length < 0)) {
								tmpElement.msg![0].text = 'No answer';
							}
							tmpElement.answer = tmpElement.msg!;
							tmpElement.msgType = index % 2 === 0 ? MessageType.Q : MessageType.A;
							delete tmpElement.msg;
							tmpElement.time = tmpElement.time//TimeUtils.setTime(tmpElement.time);
							let answersCopy = JSON.parse(JSON.stringify(tmpElement.answer));
							answersCopy.forEach((answer: any) => {
								delete answer.data;
								delete answer.entities;
								answer.category = tmpElement.category;
							});
							tmpMsg.push(tmpElement);
						});

						console.log(res, tmpMsg)

						return UknowActions.SetGuestMsgs({ msgs: tmpMsg })
					})
				)

			})
		)
	})
	constructor(
		private actions$: Actions,
		private store: Store,
		private uknowService: UKnowService,
	) { }
}