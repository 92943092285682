import { createAction, props } from '@ngrx/store';

import { AiResponseModel, ChatSidebarItemModel, ConvDriveGetAttachmentsModel, MacroTypes, QuickActionDataModel, ResponseCategoryUiCardType, SendedMessageModel, UknowAttachByHostModel } from '@models/chat.model';
import { MaiaConvModel } from '@models/chat.model';
import { UdriveFileTreeModel } from '@models/udrive.model';



export const GetChat = createAction(
	'[StoreChat] GetChat'
);
export const SetChat = createAction(
	'[StoreChat] SetChat',
	props<{ value: MaiaConvModel }>()
);

export const SetChatUknow = createAction(
	'[StoreChat] SetChat and Uknow',
	props<{ value: MaiaConvModel }>()
);


export const SearchChat = createAction(
	'[StoreChat] Search chat',
	props<{ string: string }>()
);

export const CleanChatSearch = createAction(
	'[StoreChat] Clean Searched Chat'
);

export const ChatToDel = createAction(
	'[StoreChat] Set chat to del',
	props<{ value: string }>()
);

export const EqualState = createAction(
	'[StoreChat] Chat list are equals'
);
export const GetMaiaConv = createAction(
	'[StoreChat] GetMaiaConv',
	props<{ isUknow?: boolean }>()
);

export const GetMaiaConvAndDrive = createAction(
	'[StoreChat] Get the list of conversation and Udrive'
);

export const GetMaiaConvPaged = createAction(
	'[StoreChat] Get the paged list of conversation',
	props<{ size: number, page: number }>()
);

export const GetMaiaConvPagedSliced = createAction(
	'[StoreChat] Get the slice of paged list of conversation',
	props<{ size: number, page: number, count: number }>()
);



export const NewConversation = createAction(
	'[StoreChat] NewConversation',
	props<{ value: string, msgType: string }>()
);
export const NewChatAdded = createAction(
	'[StoreChat] NewChatAdded',
	props<{ value: ChatSidebarItemModel }>()
);
export const SelectChat = createAction(
	'[StoreChat] SelectChat',
	props<{ value: ChatSidebarItemModel }>()
);
export const SelectSharedChat = createAction(
	'[StoreChat] SelectSharedChat',
	props<{ value: string }>()
);
export const SelectUknowChat = createAction(
	'[StoreChat] Set Uknow host',
	props<{ attachments: UknowAttachByHostModel, convId: string, guest: { guest_id: string, name: string, surname: string, email: string, phone: string } }>()
);

// export const NewMessageRecived = createAction(
//     '[StoreChat] NewMessageRecived',
//     props<{ newMessage: AiResponseModel }>()
// );

export const SendMessage = createAction(
	'[StoreChat] SendMessage',
	props<{ value: SendedMessageModel }>()
);

export const ReciveMessage = createAction(
	'[StoreChat] ReciveMessage',
	props<{ value: AiResponseModel }>()
);

export const MakeChatHistory = createAction(
	'[StoreChat] MakeChatHistory',
	props<{ value: any }>()
);

export const RestoreChat = createAction(
	'[StoreChat] RestoreChat',
	props<{ value: any, isMacro?: boolean }>()
);
export const RestoreShareChat = createAction(
	'[StoreChat] RestoreShareChat',
	props<{ value: any }>()
);



export const RestoreChatHistory = createAction(
	'[StoreChat] RestoreChatHistory',
	props<{ value: any }>()
);

export const LoadChat = createAction(
	'[StoreChat] LoadChat',
	props<{ value: boolean }>()
);


export const UpdateChatTitle = createAction(
	'[StoreChat] UpdateChatTitle',
	props<{ value: string }>()
);


export const ManualUpdateChatTitle = createAction(
	'[StoreChat] ManualUpdateChatTitle',
	props<{ newTitle: string, chatId?:string }>()
);


export const UpdateChatPinned = createAction(
	'[StoreChat] UpdateChatPinned',
	props<{ value: { convId: string, pinned: boolean } }>()
);

export const UpdateChatLastMessage = createAction(
	'[StoreChat] UpdateChatLastMessage',
	props<{ value: string }>()
);

export const RemoveChat = createAction(
	'[StoreChat] RemoveChat',
	props<{ value?: string[] }>()
);

export const CleanRemoveChat = createAction(
	'[StoreChat] Clean RemoveChat'
);

export const RemoveChatExchange = createAction(
	'[StoreChat] RemoveChatExchange',
	props<{ value: { id: number | undefined, text: string } }>()
);

export const onRemoveChatExchange = createAction(
	'[StoreChat] onRemoveChatExchange',
	props<{ value: { id: number, index: number | undefined } }>()
);

export const CleanChatStatus = createAction(
	'[StoreChat] CleanChatStatus'
);

export const setChatType = createAction(
	'[StoreChat] setChatType',
	props<{ value: ResponseCategoryUiCardType }>()
);

export const EmptyChat = createAction(
	'[StoreChat] Empty chat state'
);

export const setChatWait = createAction(
	'[StoreChat] set spinner on chat panel',
	props<{ value: boolean }>()
);

export const updateQuestionReasoning = createAction(
	'[StoreChat] set reasoning on last question',
	props<{ value: string }>()
);



export const getChatAttachments = createAction(
	'[StoreChat] get chatAttachments'
);


export const setChatAttachments = createAction(
	'[StoreChat] set chatAttachments',
	props<{ value: ConvDriveGetAttachmentsModel }>()
);

export const checkUpdateAttachedFile = createAction(
	'[StoreChat] Check if update Uknow chat attachments',
	props<{ updatedAttach: UdriveFileTreeModel }>()
);

export const updateChatAttachments = createAction(
	'[StoreChat] update chatAttachments status',
	props<{ updatedAttach: UdriveFileTreeModel }>()
);

export const updateUknowChat = createAction(
	'[StoreChat] update Uknow chat',
	props<{ selectedFile: number[] }>()
);

export const updateUknowChatContext = createAction(
	'[StoreChat] update Uknow chat context',
	props<{ context: string | null }>()
);

export const createUknowChat = createAction(
	'[StoreChat] create Uknow chat',
	props<{ selectedFile: number[], isQA?: boolean }>()
);

export const CleanChatAttachments = createAction(
	'[StoreChat] clean Uknow chat attachments'
);

export const CleanUknowChat = createAction(
	'[StoreChat] clean Uknow chat remote'
);

export const EmptyUknowChat = createAction(
	'[StoreChat] empty Uknow chat local'
);


export const CalculateFileEmbeddings = createAction(
	'[StoreChat] Calculate file embeddings',
	props<{ attachId: number }>()
);

export const SetChatId = createAction(
	'[StoreChat] Set conversation ID only',
	props<{ convId: string }>()
);

export const UknowWillDeleted = createAction(
	'[StoreChat] Associated Uknow will be deleted',
	props<{ uknow: number }>()
);

export const ConfirmUknowWillDeleted = createAction(
	'[StoreChat] Delete Associated'
);

export const CancelUknowWillDeleted = createAction(
	'[StoreChat] Cancel Associated'
);


export const WSMessage = createAction(
	'[StoreChat - WS notification] Update ChatList',
	props<{ updateObject: ChatSidebarItemModel, wsEvent: string }>()
)



export const WSMessageAddDeleteConvCheckList = createAction(
	'[StoreChat - WS notification] Check ChatList',
	props<{ convList: string[], wsEvent: string }>()
)

export const WSMessageAddConv = createAction(
	'[StoreChat - WS notification] Add to ChatList',
	props<{ convList: string[] | ChatSidebarItemModel, wsEvent: string }>()
)

export const WSMessageUpdatequickActionParagraph = createAction(
	'[StoreChat - WS notification] Update chat balloon',
	props<{ paragraphsData: QuickActionDataModel, idAnswer: number }>()
)


export const WSMessageUpdateImageDescriptionData = createAction(
	'[StoreChat - WS notification] Update Image Descriptions data balloon',
	props<{ imagesData: { id_adam_conv: number, update_image_description: string } }>()
)

export const StopAnswer = createAction(
	'[StoreChat] Interrupt answer'
)

export const StoppedAnswer = createAction(
	'[StoreChat] Answer interrupted',
	props<{ interruptedMsg: number }>()
)


export const MacroDocument = createAction(
	'[StoreDrive] Welcome message macro chat',
	props<{ kind: MacroTypes }>()
);


export const MacroDocumentStep2 = createAction(
	'[StoreDrive] Send macro question',
);

export const resetMacro = createAction(
	'[StoreDrive] Reset macro',
);


export const NoopAction = createAction(
	'[StoreChat] nothing to do'
);

export const resetChatState = createAction(
	'[StoreChat] reset chat state'
);


export const setChatExpertise = createAction(
	'[StoreChat] set chat expertise',
	props<{ expertise: 'base' | 'pro' }>()
);